// AdminDashboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import { RefreshCw, Printer, LogOut, Laptop, Wifi, Users, Calendar, FileText } from 'lucide-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { generatePDF } from './pdfGenerator';
import { generateExcel } from './excelGenerator';
import {
  PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const AdminDashboard = () => {
  const [registrations, setRegistrations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterClassType, setFilterClassType] = useState('');
  const [filterTrack, setFilterTrack] = useState('');
  const [isPdfGenerating, setIsPdfGenerating] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchRegistrations();
  }, []);

  // Function to remove duplicates based on student name, age, parent's name, and parent's contact
  const removeDuplicates = (data) => {
    const uniqueRegistrations = [];
    const keysSet = new Set();

    data.forEach((reg) => {
      const uniqueKey = `${reg.student_name}-${reg.age}-${reg.parents_name}-${reg.parents_mobile}`;
      
      // Only add the registration if it's not a duplicate
      if (!keysSet.has(uniqueKey)) {
        keysSet.add(uniqueKey);
        uniqueRegistrations.push(reg);
      }
    });

    return uniqueRegistrations;
  };

  const fetchRegistrations = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
      const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';
      const response = await axios.get(
          `${apiUrl}/trial-class/registrations`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
      );
      // Remove duplicate registrations
      const uniqueData = removeDuplicates(response.data);
      setRegistrations(uniqueData);
    } catch (error) {
      console.error('Error fetching registrations:', error);
      setError('Failed to fetch registrations. Please try again.');
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        localStorage.removeItem('token');
        navigate('/login');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  // Function to determine the track based on age
  const determineTrack = (age) => {
    if (age >= 6 && age <= 8) return 'Track 1 (6-8)';
    if (age >= 9 && age <= 12) return 'Track 2 (9-12)';
    return 'Track 3 (13-16+)';
  };

  const filteredRegistrations = registrations.filter((reg) => {
    const searchCheck =
        reg.student_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        reg.parents_name?.toLowerCase().includes(searchTerm.toLowerCase());

    const dateCheck =
        (!startDate || new Date(reg.registration_date) >= startDate) &&
        (!endDate || new Date(reg.registration_date) <= endDate);

    const classTypeCheck =
        !filterClassType || reg.preferred_class_type === filterClassType;

    const trackCheck =
        !filterTrack || determineTrack(reg.age) === filterTrack;

    return searchCheck && dateCheck && classTypeCheck && trackCheck;
  });

  const classTypeData = [
    { name: 'Offline', value: filteredRegistrations.filter((r) => r.preferred_class_type === 'OFFLINE').length },
    { name: 'Online', value: filteredRegistrations.filter((r) => r.preferred_class_type === 'ONLINE').length },
    { name: 'Hybrid', value: filteredRegistrations.filter((r) => r.preferred_class_type === 'HYBRID').length },
  ];

  const trackData = [
    { name: 'Track 1 (6-8)', value: filteredRegistrations.filter((r) => determineTrack(r.age) === 'Track 1 (6-8)').length },
    { name: 'Track 2 (9-12)', value: filteredRegistrations.filter((r) => determineTrack(r.age) === 'Track 2 (9-12)').length },
    { name: 'Track 3 (13-16+)', value: filteredRegistrations.filter((r) => determineTrack(r.age) === 'Track 3 (13-16+)').length },
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const handleGeneratePDF = async () => {
    setIsPdfGenerating(true);
    try {
      await generatePDF(filteredRegistrations);
    } catch (error) {
      alert(error.message);
    } finally {
      setIsPdfGenerating(false);
    }
  };

  // Function to handle the Excel file download
  const handleDownloadExcel = () => {
    generateExcel(filteredRegistrations);
  };

  return (
      <div className="min-h-screen bg-gradient-to-br from-orange-50 to-orange-100 p-8">
        <div className="max-w-7xl mx-auto space-y-8">
          <header className="bg-white rounded-2xl shadow-lg p-6">
            <div className="flex justify-between items-center">
              <h1 className="text-4xl font-bold text-orange-600">
                NextGen Programmer Dashboard
              </h1>
              <div className="flex space-x-4">
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={fetchRegistrations}
                    className="bg-green-500 text-white px-6 py-3 rounded-full hover:bg-green-600 transition duration-300 flex items-center"
                >
                  <RefreshCw size={20} className="mr-2" />
                  Refresh
                </motion.button>
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleGeneratePDF}
                    disabled={isPdfGenerating || filteredRegistrations.length === 0}
                    className={`bg-blue-500 text-white px-6 py-3 rounded-full hover:bg-blue-600 transition duration-300 flex items-center ${
                        (isPdfGenerating || filteredRegistrations.length === 0) ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                >
                  <Printer size={20} className="mr-2" />
                  {isPdfGenerating ? 'Generating PDF...' : 'Print PDF'}
                </motion.button>
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleDownloadExcel}
                    className="bg-yellow-500 text-white px-6 py-3 rounded-full hover:bg-yellow-600 transition duration-300 flex items-center"
                >
                  <FileText size={20} className="mr-2" />
                  Download Excel
                </motion.button>
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleLogout}
                    className="bg-red-500 text-white px-6 py-3 rounded-full hover:bg-red-600 transition duration-300 flex items-center"
                >
                  <LogOut size={20} className="mr-2" />
                  Logout
                </motion.button>
              </div>
            </div>
          </header>

          <section className="bg-white rounded-2xl shadow-lg p-6">
            <h2 className="text-2xl font-semibold text-orange-600 mb-4">Filters</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
              <div className="flex items-center bg-orange-100 rounded-full px-4 py-2">
                <Users size={20} className="text-orange-500 mr-2" />
                <input
                    type="text"
                    placeholder="Search by name..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="bg-transparent focus:outline-none w-full"
                />
              </div>
              <div className="flex items-center bg-orange-100 rounded-full px-4 py-2">
                <Calendar size={20} className="text-orange-500 mr-2" />
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Start Date"
                    className="bg-transparent focus:outline-none w-full"
                />
              </div>
              <div className="flex items-center bg-orange-100 rounded-full px-4 py-2">
                <Calendar size={20} className="text-orange-500 mr-2" />
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="End Date"
                    className="bg-transparent focus:outline-none w-full"
                />
              </div>
              <select
                  value={filterClassType}
                  onChange={(e) => setFilterClassType(e.target.value)}
                  className="bg-orange-100 rounded-full px-4 py-2 focus:outline-none"
              >
                <option value="">All Class Types</option>
                <option value="OFFLINE">Offline Class</option>
                <option value="ONLINE">Online Class</option>
                <option value="HYBRID">Hybrid Class</option>
              </select>
              <select
                  value={filterTrack}
                  onChange={(e) => setFilterTrack(e.target.value)}
                  className="bg-orange-100 rounded-full px-4 py-2 focus:outline-none"
              >
                <option value="">All Tracks</option>
                <option value="Track 1 (6-8)">Track 1 (6-8)</option>
                <option value="Track 2 (9-12)">Track 2 (9-12)</option>
                <option value="Track 3 (13-16+)">Track 3 (13-16+)</option>
              </select>
            </div>
          </section>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="bg-white p-6 rounded-2xl shadow-lg"
            >
              <h2 className="text-2xl font-bold mb-4 text-orange-600">
                Class Type Distribution
              </h2>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                      data={classTypeData}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                      label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                  >
                    {classTypeData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </motion.div>
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="bg-white p-6 rounded-2xl shadow-lg"
            >
              <h2 className="text-2xl font-bold mb-4 text-orange-600">
                Track Distribution
              </h2>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={trackData}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="value" fill="#8884d8">
                    {trackData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </motion.div>
          </div>

          {isLoading ? (
              <div className="text-center py-8">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-orange-500 mx-auto"></div>
                <p className="mt-4 text-lg">Loading registrations...</p>
              </div>
          ) : error ? (
              <div className="text-center py-8 text-red-600">
                <p>{error}</p>
              </div>
          ) : (
              <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.4 }}
                  className="bg-white shadow-lg rounded-2xl overflow-hidden"
              >
                <div className="overflow-x-auto">
                  <table className="min-w-full bg-white">
                    <thead className="bg-orange-500 text-white">
                    <tr>
                      <th className="py-3 px-4 text-left">Student Name</th>
                      <th className="py-3 px-4 text-left">Age</th>
                      <th className="py-3 px-4 text-left">School Name</th>
                      <th className="py-3 px-4 text-left">Parents' Name</th>
                      <th className="py-3 px-4 text-left">Parents' Contact</th>
                      <th className="py-3 px-4 text-left">Class Type</th>
                      <th className="py-3 px-4 text-left">Track</th>
                      <th className="py-3 px-4 text-left">Computer</th>
                      <th className="py-3 px-4 text-left">Internet</th>
                      <th className="py-3 px-4 text-left">Location</th>
                      <th className="py-3 px-4 text-left">Registration Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredRegistrations.length > 0 ? (
                        filteredRegistrations.map((reg, index) => (
                            <motion.tr
                                key={reg.id}
                                className={index % 2 === 0 ? 'bg-orange-50' : 'bg-white'}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.3, delay: index * 0.05 }}
                            >
                              <td className="py-3 px-4 whitespace-nowrap">{reg.student_name}</td>
                              <td className="py-3 px-4 whitespace-nowrap">{reg.age}</td>
                              <td className="py-3 px-4 whitespace-nowrap">{reg.school_name}</td>
                              <td className="py-3 px-4 whitespace-nowrap">{reg.parents_name}</td>
                              <td className="py-3 px-4 whitespace-nowrap">{reg.parents_mobile}</td>
                              <td className="py-3 px-4 whitespace-nowrap">
                          <span className={`px-2 py-1 rounded-full text-xs font-semibold ${
                              reg.preferred_class_type === 'OFFLINE' ? 'bg-blue-100 text-blue-800' :
                                  reg.preferred_class_type === 'ONLINE' ? 'bg-green-100 text-green-800' :
                                      'bg-purple-100 text-purple-800'
                          }`}>
                            {reg.preferred_class_type}
                          </span>
                              </td>
                              <td className="py-3 px-4 whitespace-nowrap">{determineTrack(reg.age)}</td>
                              <td className="py-3 px-4 whitespace-nowrap text-center">
                                {reg.has_computer ? (
                                    <Laptop size={20} className="text-green-500 inline" />
                                ) : (
                                    <span className="text-red-500">✕</span>
                                )}
                              </td>
                              <td className="py-3 px-4 whitespace-nowrap text-center">
                                {reg.has_wifi ? (
                                    <Wifi size={20} className="text-green-500 inline" />
                                ) : (
                                    <span className="text-red-500">✕</span>
                                )}
                              </td>
                              <td className="py-3 px-4 whitespace-nowrap">{reg.location}</td>
                              <td className="py-3 px-4 whitespace-nowrap">
                                {new Date(reg.registration_date).toLocaleString()}
                              </td>
                            </motion.tr>
                        ))
                    ) : (
                        <tr>
                          <td colSpan="11" className="py-8 text-center text-gray-500">
                            No registrations found.
                          </td>
                        </tr>
                    )}
                    </tbody>
                  </table>
                </div>
              </motion.div>
          )}
        </div>
      </div>
  );
};

export default AdminDashboard;
